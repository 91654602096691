import findKey from "lodash/findKey"
import { isClient } from "common/helper"
import { cleanEnv, str } from "envalid"

// console.log("process.env", process.env)

export const envConfig = cleanEnv(process.env, {
  REACT_APP_ENV: str(),
  REACT_APP_VERSION: str(),
  REACT_APP_DEFAULT_LANG: str(),
  NODE_ENV: str(),
})

const config = {
  production: {
    HOST: "https://mybenefit-app-flexben.scg.com",
    API_ENDPOINT: "https://mybenefit-app-flexben.scg.com/graphql",
    API: "https://mybenefit-api-flexben.scg.com",
  },
  uat: {
    HOST: "https://mybenefit-app-flexben-uat.scg.com",
    API_ENDPOINT: "https://mybenefit-app-flexben-uat.scg.com/graphql",
    API: "https://mybenefit-api-flexben-uat.scg.com",
  },
  staging: {
    HOST: "https://dev-scg2-flex-app.moveplus.dynu.net",
    API_ENDPOINT: "https://dev-scg2-flex-app.moveplus.dynu.net/graphql",
    API: "https://dev-scg2-flex-api.moveplus.dynu.net",
  },
  staging2: {
    HOST: "http://localhost:9903",
    API_ENDPOINT: "http://localhost:9901/graphql",
    API: "http://localhost:9901",
  },
  staging3: {
    HOST: "https://dev-scg-my-benefit-app.moveplus.dynu.net",
    API_ENDPOINT: "https://dev-scg-my-benefit-app.moveplus.dynu.net/graphql",
    API: "https://dev-scg-my-benefit-api.moveplus.dynu.net",
  },
  development: {
    HOST: "http://host.docker.internal:22903",
    API_ENDPOINT: "http://host.docker.internal:22901/graphql",
    API: "http://host.docker.internal:22901",
  },
  development2: {
    HOST: "http://localhost:22903",
    API_ENDPOINT: "http://localhost:22901/graphql",
    API: "http://localhost:22901",
  },
}

export const getEnvByHost = () => (isClient ? findKey(config, { HOST: window.location.origin }) : null)

export const env = getEnvByHost() || "production"

console.log("ENV : ", getEnvByHost())
console.log("HOST : ", window.location.origin)
console.log("GET HOST : ", config[env].HOST)

const backofficeEnvConfig = {
  production: {
    HOST: "https://mybenefit-backoffice-flexben.scg.com",
    API_ENDPOINT: "https://mybenefit-backoffice-flexben.scg.com/graphql",
    API: "https://mybenefit-api-flexben.scg.com",
  },
  uat: {
    HOST: "https://uat-scg2-flex-backoffice.moveplus.dynu.net",
    API_ENDPOINT: "https://uat-scg2-flex-backoffice.moveplus.dynu.net/graphql",
    API: "https://uat-scg2-flex-api.moveplus.dynu.net",
  },
  staging: {
    HOST: "https://dev-scg2-flex-backoffice.moveplus.dynu.net",
    API_ENDPOINT: "https://dev-scg2-flex-backoffice.moveplus.dynu.net/graphql",
    API: "https://dev-scg2-flex-api.moveplus.dynu.net",
  },
  staging2: {
    HOST: "http://localhost:9903",
    API_ENDPOINT: "http://localhost:9901/graphql",
    API: "http://localhost:9901",
  },
  staging3: {
    HOST: "https://dev-scg-my-benefit-backoffice.moveplus.dynu.net",
    API_ENDPOINT: "https://dev-scg-my-benefit-backoffice.moveplus.dynu.net/graphql",
    API: "https://dev-scg-my-benefit-api.moveplus.dynu.net",
  },
  development: {
    HOST: "http://host.docker.internal:22902",
    API_ENDPOINT: "http://host.docker.internal:22901/graphql",
    API: "http://host.docker.internal:22901",
  },
  development2: {
    HOST: "http://localhost:22902",
    API_ENDPOINT: "http://localhost:22901/graphql",
    API: "http://localhost:22901",
  },
}

export const backofficeEnv = backofficeEnvConfig[env]

export default config[env]
